import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import brochurePDF from '../assets/MAHILA VIKAS.pdf';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false); // Close the navbar on link click
  };

  return (
    <Navbar expand="lg" sticky="top" className="custom-navbar" expanded={isOpen}>
      <Container>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleMenu}
          aria-expanded={isOpen ? 'true' : 'false'}
          className="custom-toggler"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto custom-nav">
            <Nav.Link as={Link} to="/" onClick={closeMenu}>
              Home
            </Nav.Link>

            {/* Our Messages Dropdown */}
            <NavDropdown title="Our Messages" id="our-messages-dropdown">
              <NavDropdown.Item as={Link} to="/our-messages/president" onClick={closeMenu}>
                President Messages
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-messages/principal" onClick={closeMenu}>
                Principal Messages
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/our-messages/secretary" onClick={closeMenu}>
                Secretary Messages
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={HashLink} smooth to="/#about" onClick={closeMenu}>
              About Us
            </Nav.Link>

            {/* CSR Activities Dropdown */}
            <NavDropdown title="CSR Activities" id="csr-dropdown">
              <NavDropdown.Item as={Link} to="/csr-activities/csr-activities" onClick={closeMenu}>
                CSR Activities
              </NavDropdown.Item>
              <NavDropdown.Item href={brochurePDF} target="_blank" rel="noopener noreferrer">
                Project Brochure
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/events" onClick={closeMenu}>
              Events
            </Nav.Link>
            <Nav.Link as={Link} to="/administration" onClick={closeMenu}>
              Administration
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#announcementsevents" onClick={closeMenu}>
              News
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#gallery" onClick={closeMenu}>
              Gallery
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/donate-us" onClick={closeMenu}>
              Donate Us
            </Nav.Link> */}
            <Nav.Link as={Link} to="/contact" onClick={closeMenu}>
              Contacts
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
