import React from 'react';
import principalImage from '../assets/images/StudentA.png'; // Update the path to your actual image

const PrincipalMessage = () => {
  return (
    <section className="principal-message-section">
      <div className="principal-message-container">
        <div className="principal-image">
          <img src={principalImage} alt="Principal" />
        </div>
        <div className="principal-message-content">
          <h2>Principal's Message</h2>
          <p>
          Which impart learning, but it is our prayer that our students go out into the world with strength, not only to reach and excel in their professional aspirations, but to remain beautiful human beings.<br /><br />

          A school is always a 'world in miniature' where one receives a 'training for life', where effective, meaningful and joyful learning takes place. It is here that we begin to learn our lessons of life – to accept challenges, face competition, defeat and failure, and rejoice at victory and triumph.<br /><br />

          The B.D. Harne English Medium School is a symbol of progressive and quality education. Here we would like to inspire the quality “Learn, Achieve and Inspire’’ which captures the essence of our spirit. In our school, children gain an all-round education and achieve their potential not just in the academic field but also in the field of sports, creative arts, literary and cultural pursuits.<br /><br />

          The children of the school evolve as well-rounded individuals who have honed their skills in the microcosm of the school and are well equipped to lead and inspire in the microcosm of the world.<br /><br />

          Our school is also scaling new heights with excellent and reputed professionals training our children in a wide range of co-curricular activities like table tennis, karate, dance and theatre.
          </p>
          With best wishes!!
          <p>Yogeshawari G. Palode</p>
        </div>
      </div>
    </section>
  );
};

export default PrincipalMessage;
